@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

@import "bulma/sass/utilities/all";
@import "bulma/sass/base/all";
@import "bulma/sass/helpers/typography";
@import "bulma/sass/grid/columns";
@import "bulma/sass/elements/container";
@import "bulma/sass/form/_all";
@import "bulma/sass/components/tabs";

@import "../../assets/bulma-overrides/bulmaOverrides";

/* Main container for AI Calculator Generator */
.ai-calculator-generator-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px;
  font-family: $bricolage-font !important;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 1500px;
  height: auto;
  min-height: 100vh;
  margin: 0px auto;
  overflow: visible;
  width: 100%;

  /* Responsive adjustments */
  @media (max-width: 480px) {
    height: auto;
    width: 100%;
  }

  @media (max-width: 769px) {
    height: auto;
    width: 100%;
  }

  @media (min-width: 1024px) {
    width: 100vw;
    max-width: 100%;
  }

  /* Header section with back button */
  .ai-calculator-generator-header {
    position: relative;
    width: 100%;
    height: 50px;

    .ai-calculator-back-button {
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 8px;
      border-radius: 4px;
      transition: background-color 0.2s;
      position: absolute;
      left: 0;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  /* Content section */
  .ai-calculator-generator-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    /* Title and description */
    .ai-calculator-generator-title {
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 16px;
      text-align: center;
      font-family: $bricolage-font;
    }

    .ai-calculator-generator-description {
      font-size: 1rem;
      color: #666;
      text-align: center;
      max-width: 800px;
      margin-bottom: 40px;
    }

    /* Input card for calculator type */
    .ai-calculator-input-card {
      background-color: #ffffff;
      border-radius: 12px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
      padding: 32px;
      width: 100%;
      max-width: 500px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .ai-calculator-input-group {
        width: 100%;
        margin-bottom: 24px;

        label {
          display: block;
          font-weight: 600;
          margin-bottom: 12px;
          font-size: 1rem;
          color: #333333;
        }

        .ai-calculator-type-input {
          width: 100%;
          padding: 14px 16px;
          font-size: 1rem;
          border: 1px solid #e0e0e0;
          border-radius: 8px;
          background-color: #fff;
          transition: all 0.2s ease;

          &:focus {
            outline: none;
            border-color: $primary;
            box-shadow: 0 0 0 3px rgba($primary, 0.1);
          }

          &::placeholder {
            color: #999;
          }
        }
      }

      .ai-generate-calculator-button {
        background-color: $primary;
        color: white;
        border: none;
        border-radius: 8px;
        padding: 14px 28px;
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          background-color: darken($primary, 5%);
          transform: translateY(-1px);
          box-shadow: 0 4px 12px rgba($primary, 0.2);
        }

        &:active {
          transform: translateY(0);
        }

        &:disabled {
          background-color: lighten($primary, 20%);
          cursor: not-allowed;
          transform: none;
          box-shadow: none;
        }
      }
    }

    /* Results section with chat and preview */
    .ai-calculator-result-section {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      width: 100%;
      margin-top: 20px;

      @media (max-width: 992px) {
        grid-template-columns: 1fr;
      }

      /* Chat container */
      .ai-calculator-chat-container {
        display: flex;
        flex-direction: column;
        height: 600px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        overflow: hidden;

        .ai-chat-messages {
          flex: 1;
          overflow-y: auto;
          padding: 16px;
          display: flex;
          flex-direction: column;
          gap: 16px;

          .ai-chat-message {
            max-width: 80%;
            padding: 12px 16px;
            border-radius: 16px;
            word-break: break-word;

            &.ai-user-message {
              align-self: flex-end;
              background-color: $primary;
              color: white;
              border-bottom-right-radius: 4px;
            }

            &.ai-assistant-message {
              align-self: flex-start;
              background-color: #f0f0f0;
              color: $black;
              border-bottom-left-radius: 4px;
            }

            .ai-loading {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 24px;

              .ai-dot {
                width: 8px;
                height: 8px;
                background-color: $grey;
                border-radius: 50%;
                margin: 0 4px;
                animation: ai-bounce 1.4s infinite ease-in-out both;

                &:nth-child(1) {
                  animation-delay: -0.32s;
                }

                &:nth-child(2) {
                  animation-delay: -0.16s;
                }
              }

              @keyframes ai-bounce {
                0%,
                80%,
                100% {
                  transform: scale(0);
                }
                40% {
                  transform: scale(1);
                }
              }
            }
          }
        }

        .ai-chat-input-container {
          display: flex;
          padding: 16px;
          border-top: 1px solid #eee;

          .ai-chat-input {
            flex: 1;
            padding: 12px 16px;
            border: 1px solid #ddd;
            border-radius: 8px;
            font-size: 1rem;
            margin-right: 8px;

            &:focus {
              outline: none;
              border-color: $primary;
            }
          }

          .ai-send-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border: none;
            border-radius: 50%;
            background-color: $primary;
            color: white;
            cursor: pointer;
            transition: background-color 0.2s;

            &:hover {
              background-color: darken($primary, 5%);
            }

            &:disabled {
              background-color: lighten($primary, 20%);
              cursor: not-allowed;
            }
          }
        }
      }

      /* Preview section */
      .ai-calculator-preview-section {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        overflow: hidden;

        .ai-preview-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px;
          border-bottom: 1px solid #eee;

          h3 {
            font-family: $bricolage-font;
            font-size: 1.25rem;
            margin: 0;
          }

          .ai-preview-actions {
            display: flex;
            gap: 8px;

            .ai-action-button {
              display: flex;
              align-items: center;
              justify-content: center;
              border: none;
              background-color: #f0f0f0;
              color: $grey-darker;
              cursor: pointer;
              transition: background-color 0.2s;

              &.ai-text-button {
                padding: 8px 16px;
                border-radius: 4px;
                font-size: 0.9rem;
              }

              &:hover {
                background-color: darken(#f0f0f0, 5%);
              }
            }
          }
        }

        .ai-preview-container {
          padding: 16px;
          height: 500px;
          overflow: auto;

          .ai-preview-content {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

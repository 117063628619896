.article-editor-page {
  * {
    font-family: Inter !important;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  overflow: none;

  .article-editor-page-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    height: 6rem;
    padding: 2rem 1.5rem;
    align-content: center;

    @media (max-width:1515px) {
      gap: 12px;
      height: 7.5rem;
    }

    .lhs-for-small-screens {
      display: flex !important;
    }

    .left-header-section {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      margin-left: 8rem;

      @media (max-width:1000px) {
        margin-left: 1rem !important;
      }



      .abun-logo {
        width: 3rem;
        height: 3rem;
        margin: 0 1.25rem;
        cursor: pointer;
      }

      .Tabs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 0 0 2.75rem;
        width: auto;
        gap:20px;

        .Tab {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          cursor: default;
          font-size: 1.25rem;
          opacity: 0.5;

          .twya-tab {
            text-overflow: ellipsis;
            text-wrap: nowrap;
            cursor: default;
            pointer-events: none;
          }

          &.active {
            font-weight: 600;
            color: #3F77F8;
            border-bottom: 3px solid #3F77F8;
            opacity: 1;
          }
        }
        @media only screen and (max-width: 1385px) {
          .talk-with-article{
            display: none !important;
          }
        }
      }

      @media only screen and (max-width: 800px) {
        display: none;
      }

    }

    .rhs-for-small-screens {
      display: flex !important;
      justify-content: flex-end !important;
      gap: 3rem !important;

      .rhs-for-small-screens-item {
        font-size: 0.8rem !important;
        margin-bottom: 0.5rem !important;
      }

      .active {
        color: #3F77F8;
      }
    }

    .right-header-section {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      gap: 4rem;

      .copy-txt-options,
      .publish-options,
      .download-options {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .copy-txt-as-btn,
        .publish-btn,
        .download-btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.5rem 1rem;
          transition: all 0.3s ease;

          &:hover {
            color: #3F77F8;
          }

          svg {
            width: 0.5rem;
            height: 0.5rem;
            margin-left: 0.5rem;
            transition: transform 0.3s ease;
          }

          @media screen and (max-width: 534px) {
            padding: 0.5rem;
          }

        }

        .copy-txt-dropdown,
        .publish-dropdown,
        .download-dropdown {
          position: absolute;
          top: 100%;
          left: 1.5rem;
          z-index: 10;
          display: none;
          min-width: 100%;
          background-color: #fff;
          box-shadow: 0px 0.25rem 1.75rem 0.5rem rgba(0, 0, 0, 0.1);
          border-radius: 0.25rem;
          padding: 0.5rem 0;
          margin-top: 0.5rem;

          &.show {
            display: block;
          }

          .copy-txt-item,
          .download-item,
          .publish-item {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0.75rem 1rem;
            font-size: 0.875rem;
            font-weight: 500;
            color: #333;
            background-color: #fff;
            border: none;
            border-radius: 0.25rem;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &:hover {
              background-color: #f0f0f0;
              color: #3F77F8;

              svg {
                color: #3F77F8;
              }
            }

            svg {
              width: 1rem;
              height: 1rem;
              margin-right: 0.5rem;
            }
          }
        }

        .publish-dropdown {
          width: 20rem;

          .card-header {
            padding: 1rem;
            border-bottom: 1px solid #E5E5E5;
            font-size: 1.1rem;
            font-weight: 500;
          }

          .card-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: .75rem;
            padding-bottom: 0;

            .radio-group {
              display: flex;
              flex-direction: column;
              gap: 0.5rem;

              .form-control-label {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                font-size: 1rem;
              }

              .text-muted-foreground {
                font-size: 0.875rem;
                color: #6c757d;
              }
            }

            .grid {
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 0.5rem;

              .form-group {
                display: flex;
                flex-direction: column;
                gap: 0.25rem;

                label {
                  font-size: 0.875rem;
                  font-weight: 500;
                }

                .form-control {
                  padding: 0.5rem;
                  border: 1px solid #ced4da;
                  border-radius: 0.25rem;
                  font-size: 1rem;
                }
              }
            }

            .space-y-2 {
              display: flex;
              flex-direction: column;
              gap: 0.5rem;

              label {
                font-size: 0.875rem;
                font-weight: 500;
              }

              .form-control {
                padding: 0.5rem;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                font-size: 1rem;
              }
            }
          }

          footer {
            padding: 1rem;
            display: flex;
            justify-content: flex-end;

            .btn {
              padding: 0.5rem 1rem;
              background-color: #3F77F8;
              color: #fff;
              border: none;
              border-radius: 0.25rem;
              cursor: pointer;
              transition: background-color 0.3s ease;

              &:hover {
                background-color: #2A5CD5;
              }

              &:disabled {
                background-color: #B0C4FF;
                cursor: not-allowed;
              }
            }
          }
        }
      }

      .save-collapse {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;

        svg.collapse-button {
          cursor: pointer;

          &:hover path {
            fill: #3F77F8 !important;
            fill-opacity: 0.8;
          }

          &.collapsed {
            background: linear-gradient(to left, #000 29%, white 25%) !important;
          }
        }
      }

      @media screen and (min-width: 1383px) and (max-width: 1518px) {
        gap:1rem !important;
      }
      @media screen and (min-width: 1064px) and (max-width: 1383px) {
        // margin-left: 16.5rem;
        gap:2rem !important;
      }
      @media screen and (max-width: 1063px) {
        // margin-left: 16.5rem;
        gap:0 !important;
      }

      @media screen and (max-width: 800px) {
        gap: 1.46rem !important;
        font-size: 0.8rem;

        button {
          font-size: 0.8rem;
        }
      }

      @media screen and (max-width: 485px) {
        font-size: 0.5rem;

        button {
          font-size: 0.5rem;
        }
      }

    }
  }

  .article-editor-page-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem;
    overflow-y: hidden;

    @media only screen and (max-width: 1000px) {
      padding: 0rem;
    }

    strong,
    b {
      color: black !important;
    }

    .article-editor-section {
      min-width: 80%;
      max-width: 80%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 1rem;
      overflow-y: auto;

      &.collapsed {
        min-width: 100%;
        max-width: 100%;
      }

      .article-editor * {
        font-family: sans-serif !important;
      }

      .article-editor {
        position: relative;
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 1rem 9rem;

        @media screen and (min-width: 2560px) and (min-height: 1440px) {
          padding-left: 20rem;
        }

        .article-title {
          font-size: 2.5rem;
          font-weight: 700;
          margin-left: 8px;
          opacity: 1;
          display: flex;
          cursor: default;
          line-height: 1.1;
          color: #000;

          @media screen and (max-width: 600px) {
            font-size: 1.5rem;
          }

        }

        .edit-title {
          display: none;
          cursor: pointer;
          width: 1rem;
          height: 1rem;
        }

        &:hover .edit-title {
          display: flex;
        }

        .aie-container {
          border: none !important;
          min-height: 100%;
          position: sticky;

          ul {
            list-style-type: disc;
          }

          aie-header * {
            border-bottom: none;
          }

          aie-header {
            padding: 0 .75rem;
            margin: 1rem 0;
            zoom: 1.25;
            position: sticky;
            top: -18px;
            z-index: 100;
          }

          .aie-content {
            overflow: hidden;

            @media only screen and (max-width: 1000px) {
              padding: 10px 0px;
            }
          }

          aie-footer {
            display: none !important;
          }
        }

        div.data-tippy-root {
          display: none !important;
        }

        @media only screen and (max-width: 1000px) {
          padding: 1rem;
          width: 100%;
        }
      }

      .article-feedback-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 4.25rem;
        margin-left: auto;
        height: 3rem;

        svg {
          cursor: pointer;
          margin-right: 0.5rem;

          &.negative-feedback {
            // rotate 180 degrees
            transform: rotate(180deg);
          }

          &.active {
            path {
              fill: #3F77F8;
            }
          }

          @media only screen and (max-width: 1000px) {
            width: 18px;
            height: 18px;
            margin-right: 1rem;
          }
        }
      }
    }

    .article-generating {
      width: 80%;
      height: 88vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .publish-post-options-for-small-screens {
      background-color: white;
      padding: 20px;
      max-width: 300px;
      box-shadow: -41px 2px 16px rgba(0, 0, 0, 0.2);
      height: 100%;
      position: absolute;
      margin-left: -287px;
      margin-top: -16px;

      h2 {
        font-size: 18px;
        margin-bottom: 10px;
      }

      .publish-post-option {
        margin-bottom: 20px;

        label {
          font-weight: bold;
        }

        p {
          margin: 5px 0 10px;
          color: #666;
          font-size: 12px;
        }

        .integration-done-mt {
          margin-top: 3rem;
        }

      }

      .publish-post-option-for-small-screens {
        margin-bottom: 10px !important;
      }

      .form-group {
        margin-bottom: 15px;

        label {
          display: block;
          margin-bottom: 5px;
          font-weight: bold;
        }

        select {
          width: 100%;
          padding: 8px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
      }

      .publish-post-btn {
        display: block;
        width: 100%;
        padding: 10px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: #0056b3;
        }
      }

      .publish-post-copy-btn {
        display: block;
        width: 30%;
        padding: 10px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-left: auto;

        &:hover {
          background-color: #0056b3;
        }
      }
    }

    .article-editor-sidebar-container {
      width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 0 1.5rem;
      overflow-y: auto;

      .sidebar-items {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        border-bottom: 1px solid #E5E5E5;
        padding: .75rem 0;

        .sidebar-items-image-remove{
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          width: 100%;
        }

        h6 {
          font-size: 1.125rem;
          font-weight: 400;
          color: #3F77F8;
          width: 100%;
          font-family: Inter;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 0.25rem;
          cursor: pointer;

          &.featured-image {
            cursor: default;
          }

          svg {
            width: 1rem;
            height: 1rem;
            margin-right: 0.5rem;

            &.active {
              transform: rotate(180deg);
            }
          }
        }

        .remove-featured-image {
          width: 90%;
          text-align: end;
          font-size: 0.75rem;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.8);
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          margin-bottom: .125rem;
          cursor: pointer;

          svg {
            width: .75rem;
            height: .75rem;
            margin-left: .5rem;
          }

          &:hover {
            //light red color:
            color: #FF4D4F;

            svg {
              path {
                fill: #FF4D4F;
                fill-opacity: 0.8;
              }
            }
          }
        }

        .FeaturedImageSection-Buttons {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
          align-items: center;

          .GenerateNew {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 0.5rem;
            padding: 0.5rem;
            font-size: 0.875rem;
            font-weight: 400;
            color: #294586;
            cursor: pointer;

            &:hover {
              color: #3F77F8;

              svg {
                path {
                  fill: #3F77F8;
                  fill-opacity: 0.8;
                }
              }
            }

            svg {
              width: 1rem;
              height: 1rem;
            }
          }

          .manual-upload-btn {
            cursor: pointer;

            &:hover {
              color: #3F77F8;
            }
          }

          svg {
            width: 1rem;
            height: 1rem;
            cursor: pointer;

            &:hover path {
              fill: #3F77F8;
              fill-opacity: 0.8;
            }
          }
        }

        .stats-container {
          .stats {
            width: 100%;
            display: flex;
            flex-direction: row;

            span.stat {
              font-weight: 600;
              margin-left: 0.25rem;

              img {
                margin-left: .3125rem;
                width: 0.85rem;
              }
            }
          }
        }

        .sidebar-item-contents {
          width: 100%;
          display: none;

          &.expanded {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            gap: 0.35rem;
          }

          .backlinks {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 0.125rem;

            .backlink {
              width: 90%;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              flex-wrap: wrap;
              border-radius: 0.5rem;
              margin-bottom: 0.5rem;
              cursor: pointer;

              span {
                font-size: .875rem;
                min-width: 60%;
                max-width: 85%;
                overflow: hidden;
                text-overflow: ellipsis;
                text-wrap: nowrap;
              }

              .backlink-url {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                border-radius: 0.5rem;
                margin-bottom: 0.5rem;
                cursor: pointer;

                input.link-edit-input {
                  font-size: 0.75rem;
                  font-weight: 400;
                  color: #000;
                  width: 100%;
                }

                .link {
                  font-size: 0.75rem;
                  font-weight: 400;
                  color: #0F82AF;
                  min-width: 20%;
                  max-width: 85%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-wrap: nowrap;
                  text-decoration: underline;

                  &.posted {
                    max-width: 80%;
                  }
                }

                svg {
                  width: .75rem;
                  height: .75rem;
                  margin-left: 0.5rem;
                }
              }
            }

            svg.remove-link {
              width: 1rem;
              height: 1rem;
              visibility: hidden;
              cursor: pointer;

              &:hover {
                path {
                  //red color
                  fill: #FF3333;
                  fill-opacity: 0.8;
                }
              }
            }

            &:hover svg.remove-link {
              visibility: visible;
            }
          }

          .meta-description-content {
            font-weight: 400;
            font-size: 0.875rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            line-height: 1.75;

            .copy-to-clipboard-container {
              width: 100%;
              display: flex;
              margin-top: 0.5rem;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;

              svg {
                width: 1rem;
                height: 1rem;
                margin-right: 0.5rem;
              }

              &:hover {
                cursor: pointer;
                color: #3F77F8;
              }
            }
          }
        }

        .generate-new-backlink {
          color: #294586;
          width: 100%;
          display: flex;
          align-items: center;

          &:hover {
            color: #3F77F8;

            svg {
              path {
                fill: #3F77F8;
                fill-opacity: 0.8;
              }
            }
          }

          svg {
            width: 1rem;
            height: 1rem;
            margin-right: 0.5rem;
          }

          .generate-new-backlink-text {
            font-size: 0.875rem;
            font-weight: 400;
            cursor: pointer;

            &:hover {
              color: #3F77F8;
            }
          }
        }
      }
    }
  }
}


.featured-image-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid #E5E5E5;
  border-radius: 0.5rem;
  margin-top: 1rem;

  .featured-image-upload-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border: 1px solid #E5E5E5;
    border-radius: 0.5rem;
    margin-top: 1rem;
  }
}
@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

@import "bulma/sass/utilities/all";
@import "bulma/sass/base/all";
@import "bulma/sass/helpers/typography";
@import "bulma/sass/grid/columns";
@import "bulma/sass/elements/container";
@import 'bulma/sass/form/_all';
@import "bulma/sass/components/tabs";

@import "../../assets/bulma-overrides/bulmaOverrides";

.content-schedule {
    gap: 1.5rem;
    padding: 2rem;
    background: #ffffff;
    min-height: 100vh;
    width: 100%;

    .content-header {
        display: flex;
        justify-content: space-between;

        @media(max-width:767px) {
            flex-direction: column;
        }
    }

    h1 {
        font-family: $bricolage-font !important;
        font-size: 2rem !important;
        font-weight: 600;
        margin-bottom: 20px;

        @media(max-width:767px) {
            margin-bottom: 30px;
        }
    }

    .container {
        display: flex;
        flex: 1;
        gap: 1.5rem;
    }

    .content-container {
        display: flex;

        @media(max-width:767px) {
            flex-direction: column;
        }

        .sidebar {
            width: 320px;
            background: #f8f9fa;
            padding: 1.5rem;
            border-radius: 12px;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
            border: 1px solid #d1d5db;

            @media(max-width:767px) {
                width: 400px;
                margin-bottom: 10px;
            }

            @media(max-width:480px) {
                width: 320px;
            }

            h3 {
                margin-bottom: 1rem;
                color: #333;
            }

            .filters {
                display: flex;
                justify-content: space-between;
                margin-bottom: 1rem;

                button {
                    flex: 1;
                    padding: 10px;
                    background: #007bff;
                    color: white;
                    border: none;
                    border-radius: 8px;
                    cursor: pointer;
                    transition: background 0.3s ease;

                    &:hover {
                        background: #0056b3;
                    }

                    &:not(:last-child) {
                        margin-right: 5px;
                    }
                }
            }

            .article {
                background: rgb(239, 246, 255);
                padding: 10px;
                border-radius: 8px;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 8px;
                cursor: grab;
                color: rgba(30, 64, 175, 1);

                &.dragging {
                    opacity: 0.5;
                }

                .article-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                }

                span {
                    flex: 1;
                }

                button {
                    background: transparent;
                    border: none;
                    cursor: pointer;
                    font-size: 18px;
                    color: #dc3545;

                    &:hover {
                        color: #b02a37;
                    }
                }

                .article-info {
                    margin-top: 4px;
                    display: flex;
                    align-items: flex-start;
                    gap: 8px;
                }

                .article-date,
                .article-status {
                    font-size: 14px;
                    color: gray;
                }

                .icon {
                    color: gray;
                }

                .icon.scheduled {
                    color: green;
                }

                .icon.draft {
                    color: orange;
                }

            }
        }

        .calendar-container {
            flex: 2;
            background: #f8f9fa;
            border-radius: 12px;
            padding: 1.5rem;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

            .fc {
                height: 100% !important;
                overflow: hidden;

                @media(max-width:767px) {
                    height: 100vh;
                    width: 420px;
                }

                @media(max-width:480px) {
                    width: 300px;
                }

            }

            .fc-scrollgrid {
                height: 100% !important;
            }

            .fc-timeDropdown-button {
                background: none !important;
                border: none !important;
                box-shadow: none !important;
                padding: 0 !important;
            }

            .fc-toolbar {
                display: flex;
                justify-content: space-between;
                align-items: center;
                // gap: 12px;
                flex-wrap: nowrap;
            }

            .fc-toolbar-chunk {
                display: flex;
                align-items: center;
            }

            .fc-button {
                padding: 5px 10px;
                font-size: 14px;
                background: none !important;
                box-shadow: none !important;
                color: #333;
            }

            .fc-today-button {
                font-family: $bricolage-font !important;
                height: 35px;
                padding: 6px 12px;
                font-size: 14px;
                border-radius: 6px;
                cursor: pointer;
                color: #333;
            }
            .fc-dayGridMonth-button{
                font-family: $bricolage-font !important;
                height: 35px;
                padding: 6px 12px;
                font-size: 14px;
                border-radius: 6px;
                cursor: pointer;
                color: #333;
            }

            .fc-toolbar-title {
                padding-right: 12px;
            }

            .fc-daygrid-event:focus,
            .fc-daygrid-event:hover {
                box-shadow: none !important;
                outline: none !important;
                border: none !important;
                background: inherit !important;
            }

        }
    }
}

.custom-event {
    box-shadow: none !important;

    .event-title-box {
        background: rgb(239, 246, 255);
        color: rgba(30, 64, 175, 1);
        font-size: 12px;
        font-weight: bold;
        padding: 6px 10px;
        border-radius: 6px;
        display: inline-block;
        width: 100%;
        max-width: 160px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
        box-shadow: none !important;
        outline: none !important;
        border: none !important;
    }

    .event-title-box:focus,
    .event-title-box:hover {
        box-shadow: none !important;
        outline: none !important;
        border: none !important;
        cursor: grab;

        .delete-event-btn {
            display: flex;
        }
    }

    .event-time {
        font-size: 12px;
        padding: 3px 6px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        gap: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background: orange;
        color: white;
    }

    .event-time.published {
        background-color: rgba(30, 64, 175, 1);
        width: fit-content;
    }


    .event-time .icon {
        color: white;
    }

    .delete-event-btn {
        position: absolute;
        top: 2px;
        right: 0px;
        background: #f87171;
        color: white;
        border: none;
        width: 14px;
        height: 14px;
        font-size: 12px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        box-shadow: none;
        display: none;
    }

    .delete-event-btn:hover {
        background: #dc2626;
        box-shadow: none;
    }

    .delete-event-btn:focus {
        outline: none;
        box-shadow: none;
    }
}
.internal-link-summary-cell {
    position: relative;
    max-width: 300px;
    cursor: pointer;

    .summary-content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .summary-tooltip {
        display: none;
        position: absolute;
        left: 0;
        top: 100%;
        background: white;
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 8px;
        z-index: 1000;
        width: max-content;
        max-width: 400px;
        white-space: normal;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    &:hover .summary-tooltip {
        display: block;
    }
}

.analysis-progress-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 80vh;
    padding: 2rem;
    gap: 1.5rem;
    background: #fff;

    .analysis-card {
        background: white;
        border-radius: 12px;
        padding: 2rem;
        width: 100%;
        max-width: 800px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

        .analysis-title {
            font-family: 'Inter', sans-serif;
            font-size: 2.5rem;
            font-weight: 600;
            color: #000;
            margin-bottom: 0.75rem;
        }

        .analysis-subtitle {
            font-family: 'Inter', sans-serif;
            font-size: 1.125rem;
            color: #666;
            margin-bottom: 3rem;
        }

        .progress-bar {
            height: 8px;
            background: #f0f0f0;
            border-radius: 4px;
            margin: 2rem 0;
            overflow: hidden;

            .progress-fill {
                height: 100%;
                background: #4a90e2;
                border-radius: 4px;
                transition: width 0.3s ease;
            }
        }

        .analysis-stats {
            display: flex;
            justify-content: space-between;
            margin: 2rem 0;
            text-align: left;

            .stat-item {
                h2 {
                    font-family: 'Inter', sans-serif;
                    font-size: 2rem;
                    font-weight: 600;
                    color: #000;
                    margin-bottom: 0.5rem;
                }

                p {
                    font-family: 'Inter', sans-serif;
                    font-size: 1rem;
                    color: #666;
                }
            }
        }
    }

    .analysis-steps-card {
        background: white;
        border-radius: 12px;
        padding: 2rem;
        width: 100%;
        max-width: 800px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

        h3 {
            font-family: 'Inter', sans-serif;
            font-size: 1.25rem;
            font-weight: 600;
            color: #000;
            margin-bottom: 1.5rem;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                font-family: 'Inter', sans-serif;
                font-size: 1rem;
                color: #666;
                margin-bottom: 1rem;
                padding-left: 2rem;
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 12px;
                    height: 12px;
                    background: #ccc;
                    border-radius: 50%;
                    transition: background 0.3s ease;
                }

                &.completed {
                    color: #2ecc71;

                    &:before {
                        content: "✓";
                        background: #2ecc71;
                        color: white;
                        text-align: center;
                        line-height: 12px;
                        font-size: 8px;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .next-steps-card {
        background: white;
        border-radius: 12px;
        padding: 2rem;
        width: 100%;
        max-width: 800px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

        h3 {
            font-family: 'Inter', sans-serif;
            font-size: 1.25rem;
            font-weight: 600;
            color: #000;
            margin-bottom: 1rem;
        }

        p {
            font-family: 'Inter', sans-serif;
            font-size: 1rem;
            line-height: 1.5;
            color: #666;
        }
    }
}

.internal-link-summary-cell {
    display: flex;
    align-items: center;
    gap: 4px;
    position: relative; 

    
    .summary-content {
        flex: 1;
    }

    .internal-link-edit-button {
        opacity: 0;
        visibility: hidden;
        border: none;
        background: none;
        cursor: pointer;
        padding: 2px 6px;
        transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
        text-decoration: none;  
    }

    &:hover .internal-link-edit-button {
        opacity: 1;
        visibility: visible;
        text-decoration: none;  
    }
}

@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

@import "bulma/sass/utilities/all";
@import "bulma/sass/base/all";
@import "bulma/sass/helpers/typography";
@import "bulma/sass/grid/columns";
@import "bulma/sass/elements/container";
@import "bulma/sass/elements/form";
@import "bulma/sass/components/tabs";

@import "../../assets/bulma-overrides/bulmaOverrides";

.comp-research-page-title {
    font-size: 1.75rem;
    font-family: $secondary-font;
}

.comp-research-logo-container {
    width: 2rem;
    height: 2rem;
}

.comp-research-table-logo {
    width: 100%;
    min-width: 1.5rem;
    height: auto;
    display: inline-block;
    object-fit: cover;
    border-radius: 50%;
}

.comp-research-table-button {
    min-width: 135px;
}

.blur-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 80%);
    z-index: 100;

    .confirmation-card {
        position: absolute !important;
        width: 600px;
        height: 325px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        z-index: 100;
        padding: 20px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;

        button.go-back-button {
            width: 150px;
            background-color: grey;
        }

        button.delete {
            position: relative;
            top: -5%;
            left: 50% !important;
        }

        .confirmation-card-content {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            height: 100%;

            .comp-research-location-select {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                min-height: 13rem;

                .comp-info {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 0.5rem;

                    .comp-logo {
                        width: 1.5rem;
                        height: 1.5rem;
                        border-radius: 50%;
                        object-fit: cover;
                    }

                    .comp-name {
                        font-size: 1.5rem;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

.blur-background.hidden {
    display: none;
}

// table
#competitor-research-table {
    .abun-table-dropdown-menu-container {
        top: 7.75rem;
    }

    .abun-table-filter-buttons {
        display: none !important;
    }

    .abun-table-content .abun-table-button-container button {
        gap: 0.5rem;
        width: max-content;
    }

    table thead tr {
        th:first-child {
            width: 80%;
        }
    }
}

.back-btn.steal-comp-keywords {
    position: relative;
    top: 1.5rem;
    left: 5px !important;
    margin: 1rem;
    z-index: 4;

    &.warning-is-present {
        top: 8rem;
    }
}

.main-container.expanded .back-btn.steal-comp-keywords {
    left: 1rem !important;
}

.card-container{
    margin-top: -3.7rem;

    .card-content{
        h1{
            padding-left: 18px;
            font-weight: normal !important;
        }

    }
}

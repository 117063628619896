@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

@import "bulma/sass/utilities/all";
@import "bulma/sass/base/all";
@import "bulma/sass/helpers/typography";
@import "bulma/sass/grid/columns";
@import "bulma/sass/elements/container";
@import 'bulma/sass/form/_all';
@import "bulma/sass/components/tabs";

@import "../../assets/bulma-overrides/bulmaOverrides";

:root {
  --bulma-primary-h: 124deg;
  --bulma-primary-s: 30%;
  --bulma-primary-l: 56%;
  --bulma-link-h: 120deg;
  --bulma-link-s: 76%;
  --bulma-link-l: 33%;
  --bulma-info-h: 39deg;
  --bulma-info-s: 48%;
  --bulma-info-l: 55%;
  --bulma-success-h: 0deg;
  --bulma-success-s: 69%;
  --bulma-success-l: 49%;
  --bulma-warning-h: 0deg;
  --bulma-warning-l: 27%;
}

html,
body {
  /* Full viewport width */
  width: 100vw;
  overflow-x: hidden;
}

svg.back-btn {
  position: relative;
  width: 1.5938rem;
  height: 1.375rem;
  cursor: pointer;

  &.balance {
    pointer-events: none;
    opacity: 0;
  }
}

.keyword-research-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;

  @media(width:480px) {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    padding: 20px 30px;
  }

  h2 {
    font-family: $bricolage-font !important;
    font-size: 2rem !important;
    font-weight: 400;
  }

  p {
    color: #000000B2;
    font-size: 1.125rem !important;
    font-family: $inter-font !important;
  }

  .create-article-form {
    width: 100%;

    .form-group {
      margin-bottom: 15px;

      &.location-select {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .location-input {
          width: 100%;
        }

        .location-select {
          width: 100%;
        }
      }

      label {
        display: flex;
        margin-bottom: 5px;
        font-weight: 500;
        color: #555;
        align-items: center;
        justify-content: space-between;
      }

      .tooltip {
        display: none;
        background-color: #333;
        color: #fff;
        padding: 5px 10px;
        border-radius: 5px;
        position: absolute;
        z-index: 1000;
        font-size: 14px;
        right: 3.75rem;
        top: 5rem;

        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #333;
          bottom: -5px;
          right: 27px;
        }

        &.second {
          top: 10rem;

          &:before {
            top: -5px;
          }
        }
      }

      input {
        width: 100%;
        padding: 10px 15px;
        border: 1px solid #ddd;
        border-radius: 5px;
        font-size: 16px;
        color: #333;
      }

      input:focus {
        border-color: #4CAF50;
        outline: none;
      }

      .info-icon {
        margin-left: 5px;
        color: #888;
        cursor: pointer;

        &:hover+.tooltip {
          display: block;
        }
      }
    }

    .submit-btn {
      background-color: #4CAF50;
      color: #fff;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      font-size: 16px;
      cursor: pointer;
      width: 100%;
    }

    .submit-btn:hover {
      background-color: #45A049;
    }
  }

  .menu-btns-container {
    display: flex !important;
    flex-wrap: wrap;
    gap: 1rem 1.5rem;
    justify-content: center !important;
    margin-top: 1rem;
    width: 847px !important;
    height: 159px !important;
    //border-bottom: 2px solid rgb(219, 219, 219) ;
    // should always have 3 buttons in a row

    @media(max-width:480px) {
      /* Stack buttons vertically */
      flex-direction: column;
      padding-left: 280px;
    }

    .menu-btns {
      display: flex !important;
      flex-direction: row;
      align-items: center;
      width: 265px !important;
      height: 69px !important;
      gap: 0px;
      border: 0.5px solid #000000CC;
      border-radius: 5px;
      cursor: pointer;
      padding: 0rem !important;
      color: #333;
      font-size: .9rem;
      transition: all 0.3s ease;
      position: relative;

      @media(max-width:480px) {
        &:nth-child(2) {
          top: 170px;
        }

        &:nth-child(4) {
          top: -170px;
        }
      }


      .important-width-40 {
        width: 35px !important;
      }

      svg {
        width: 1.4rem !important;
        height: 1.4rem !important;
        margin: .75rem !important;
      }

      .menu-btn-text {
        display: flex;
        align-items: center;
        font-family: $bricolage-font !important;
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        color: #000000;
        width: 260px;
        height: 44px;
      }

      &:hover {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

        svg #hover {
          fill: #8fbffa !important;
        }
      }

      &:active {
        background-color: #e9f7ff;
        border-color: #3f77f8;
        box-shadow: 0 0 10px rgba(0, 123, 255, 0.1);
      }
    }

    &.automation-project {
      height: 50px !important;
      //AI-keyword-research-btn

      .menu-btns {
        width: 19rem !important;

        // .menu-btn-text {
        //   max-width: 15rem;
        // }
      }
    }
  }

  .table-container {
    // margin-top: 3rem;
    width: 96.5%;
    min-height: 60vh;


    .abun-table-no-data {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30vh;
      font-size: 1.5rem;
      color: #9f9f9f;
    }

    .abun-table-responsive {
      overflow-x: auto;

      h1.abun-table-title {
        font-family: Bricolage Grotesque;
        font-size: 1.5rem !important;
        font-weight: 400;
        line-height: 3rem !important;
        text-align: left;
        color: #3F77F8;
      }

      tbody {

        td:first-child {
          width: 50%;
          text-align: start !important;
        }

        td {
          text-align: center;
        }
      }

      thead {

        // first th
        th:first-child div {
          font-family: $inter-font;
          margin: 0px !important;
        }

        th div {
          font-family: $inter-font;
          margin: auto;
        }
      }

    }
  }

  .keyword-project-name-container {
    display: flex;
    align-items: center;
    gap: 8px;

    .project-edit-button {
        opacity: 0;
        border: none;
        background: none;
        cursor: pointer;
        padding: 2px 6px;
        transition: opacity 0.2s ease;
    }

    &:hover {
        .project-edit-button {
            opacity: 1;
        }
    }
  }
}

.connect-website-modal {

  .modal-background {
    background-color: rgba(0, 0, 0, 0.5);
    // disable scrolling
    overflow: hidden;
  }

  .modal-content {
    max-height: 90vh !important;
    width: 70vw !important;
    min-height: 60vh !important;

    .modal-card {
      width: 100% !important;
      min-height: 100% !important;
    }
  }
}

.ai-keyword-research-container,
.keyword-project-container,
.import-keywords-using-csv-container,
.import-gsc-keywords-container,
.manual-add-keywords-container {
  font-family: Nunito;
  width: 100%;
  display: flex !important;
  align-items: center;
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 100rem;
  min-height: 95vh !important;
  max-height: auto !important;
  overflow-y: none;
  justify-content: flex-start !important;
  gap: 1rem;

  .ai-keyword-research-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;

    h3 {
      font-size: 2rem !important;
    }
  }

  .ai-keyword-research-content {
    width: 42.5rem;
    min-height: 31.125rem;
    max-height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    align-items: center;
    gap: 1rem;
    flex-wrap: nowrap !important;
    margin: auto !important;

    @media (max-width:942px) {
      width: 100%;
    }

    h1 {
      color: $primary;
      text-align: center;
      font-size: 32px !important;
      font-weight: 900;
      line-height: 1.2;
    }

    p {
      color: #9f9f9f;
      font-size: 1.25rem !important;
      text-align: center;
    }

    form {
      width: 70%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
      height: 100%;

      @media (max-width:715px) {
        width: 77%;
      }

      @media (max-width:715px) {
        .remove-extra-keyword{
          margin-left:6px;

        }
      }

      .form-group {
        width: 60%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &.extras {
          width: 75%;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;

          .MuiFormControl-root {
            width: 285px !important;
          }

          .remove-extra-keyword {
            background-color: #FCE9E9;
            /* Make the background transparent */
            border: none;
            /* Remove default border */
            color: #FF0000;
            /* Set the text color to pink */
            font-size: 15px;
            /* Increase the font size for visibility */
            cursor: pointer;
            /* Change the cursor to a pointer when hovered */
            padding: 0;
            /* Remove default padding */
            outline: none;
            /* Remove default outline */
            display: flex;
            /* Flexbox for centering */
            align-items: center;
            /* Vertically center */
            justify-content: center;
            /* Horizontally center */
            // make it a circle
            border-radius: 49%;
            width: 1.4rem;
            height: 1.4rem;

            &:hover {
              color: deeppink;
              /* Change color on hover */
            }
          }

          .balancer {
            opacity: 0;
            pointer-events: none;
          }
        }

        &.hidden {
          display: none;
        }

        .control.undefined {
          width: 100%;

          input {
            height: 3.5rem;
          }
        }

        &.add-more {
          justify-content: flex-start;

          button {
            background: transparent;
            color: black;
            border: none;
            font-size: 1rem;
            cursor: pointer;
            opacity: 0.8;

            &.disabled {
              pointer-events: none;
              cursor: not-allowed;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  .keyword-project-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0rem;
  }

  .keyword-project-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: center;
    gap: 1rem;

    h1 {
      color: $primary;
      text-align: center;
      font-size: 1.5rem !important;
      font-weight: 500;
    }

    .dot-separator {
      color: black;
      opacity: 0.2;
    }

    // to remove dot while changing the ui from latop to mobile
    @media (max-width: 1200px) {
      .dot-separator {
        display: none;
      }
    }

    .keyword-project-infos {
      width: 515px !important;
      height: 62px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      @media(max-width:480px) {
        height: 140px;
      }


      .keyword-project-info {
        display: flex;
        flex-direction: row;
        width: 80% !important;
        align-items: center !important;
        justify-content: space-between !important;
        font-size: 1rem !important;
        text-wrap: nowrap;

        @media(max-width:480px) {
          flex-direction: column;
          margin-bottom: 10px;

          .dot-separator {
            display: none;
          }

        }

        img {
          max-width: 1.5rem !important;
          margin-left: .25rem;

          @media (max-width: 480px) {
            position: fixed; // Fix the position for small screens
          }
        }

      }

      .keyword-project-additional-info {
        display: flex;
        flex-direction: row;
        width: 180%;
        align-items: center !important;
        justify-content: space-between !important;
        font-size: .925rem;

        @media(max-width:480px) {
          width: 100%;
          flex-direction: column;

          .dot-separator {
            display: none;
          }
        }

        .very-easy {
          color: #6EB173;
        }

        .easy {
          color: #149414;
        }

        .moderate {
          color: #C39C54;
        }

        .hard {
          color: #D22727;
        }

        .very-hard {
          color: #8B0000;
        }
      }

      //Just changing the max-width so that ui doesnot break anymore
      @media (max-width: 1320px) {
        .keyword-project-additional-info {
          width: 100% !important;
          flex-wrap: wrap !important;
          justify-content: center !important;
          /* Add gap for small screens */
          gap: 15px;
        }
      }

      @media (max-width: 768px) {
        .keyword-project-additional-info {
          flex-direction: column;
          align-items: center !important;
          gap: 10px;
          margin-bottom: 20px;
        }

        .btns-container {
          /* Stack buttons vertically on small screens */
          flex-direction: column;
          gap: 5px;
        }
      }
    }

    .table-container {
      width: 96.5%;
      min-height: 60vh;

      .loadingDataCard {
        box-shadow: none !important;
      }

      .abun-table-responsive {
        width: 100%;
        height: 100%;
        overflow: hidden;

        @media(max-width:480px) {
          overflow-x: auto;
        }

        .abun-table-container {
          min-height: 60vh;
          position: relative;

          .abun-table-filter-buttons {
            width: 60% !important;

            .btns-container {
              @media(max-width:480px) {
                flex-direction: column;
                align-items: center;
              }

              .filter-button:first-child {
                @media(max-width:480px) {
                  margin-right: 13px;
                }
              }
            }
          }
        }

        tbody {
          td:first-child {
            width: 50%;
            text-align: start !important;
          }

          td {
            text-align: center;
          }
        }

        thead {

          // first th
          th:first-child div {
            font-family: $inter-font;
            margin: 0px !important;
          }

          th div {
            font-family: $inter-font;
            margin: auto;
          }
        }
      }
    }
  }

  .keywords-csv-upload {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: auto !important;
  }

  .GSC-integration {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: auto !important;

    .gs-integration-item {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;

      img {
        width: 2.5rem;
        height: 2.5rem;
      }

      .integration-item-content {
        display: flex;
        flex-direction: column;

        h1 {
          font-size: 1.125rem;
          font-weight: 900;
        }

        p {
          color: #9f9f9f;
          font-size: 1rem;
        }
      }
    }

    button {
      width: 9rem;
      height: 2rem;
      border-radius: 2px !important;
    }
  }

  .abun-table-responsive {
    width: 100%;

    .abun-table-dropdown-menu-container {
      top: 11.5rem !important;
      left: 21.125rem !important;

      .abun-table-dropdown-trigger {
        font-size: 0.875rem;
      }
    }
  }

  .keywords-manual-upload {

    .input-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;

      .keywords-input {
        input {
          width: 24.375rem !important;
          @media (max-width:430px) {
            width:100% !important;
        }
        }

        // manual-keyword-textarea-responsive
        textarea{
          @media (max-width:515px) {
              width:22rem !important;
          }

          @media (max-width:380px) {
              width:auto !important;
          }

        }

        .suggestions-dropdown {
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          background: white;
          border: 1px solid #ddd;
          border-radius: 4px;
          max-height: 200px;
          overflow-y: auto;
          z-index: 1000;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

          .suggestion-item {
            padding: 8px 12px;
            cursor: pointer;
            border-bottom: 1px solid #eee;
            transition: background-color 0.2s ease;
          }

          .suggestion-item:hover {
            background-color: #f5f5f5;
          }

          .suggestion-item.selected {
            /* Different color for keyboard selection */
            background-color: #e0e7ff;
            border-left: 3px solid #4f46e5;
            padding-left: 9px;
          }

          /* When both hover and selected */
          .suggestion-item.selected:hover {
            background-color: #d1d5ff;
          }
        }
      }

      .location-input {
        .css-hqvjvd-MuiAutocomplete-root {
          width: 390px !important;
        }
      }
    }

    .entered-keywords {
      max-height: 60vh !important;
      overflow-y: auto;
      border: 1px solid #d8d8d8;
      border-radius: 5px;
    }
  }
}

.gsc-domain-item {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  margin-bottom: 1rem;

  .gsc-domain-item-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    h1 {
      font-size: 1.125rem;
      font-weight: 900;
    }

    p {
      color: #9f9f9f;
      font-size: 1rem;
    }
  }

  button {
    width: 9rem;
    height: 2rem;
    border-radius: 2px !important;
  }
}


.input-measure {
  visibility: hidden;
  position: absolute;
  white-space: nowrap;
  padding: 5px;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
}

.keyword-title-table-header {
  text-align: center;

  h1 {
    color: black;
    text-align: center;
    font-size: 2rem !important;
    font-weight: 500;
  }

  h2 {
    color: black;
    text-align: center;
    font-size: 1.5rem !important;
    font-weight: 500;
    display: inline-block;
  }

  p {
    font-size: 1rem !important;
    text-align: center;

    img {
      margin-left: auto;
    }
  }

  span {
    color: $primary;
    text-align: center;
    font-size: 1.5rem !important;
    font-weight: 500;
  }
}


.time-estimation {
  width: 45rem;
  border: 0.5px solid #000000CC;
  border-radius: 5px;


  .card-content .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h1 {
      font-size: 1.5rem !important;
      font-weight: 500;
    }

    p {
      font-size: 1rem !important;
      color: #9f9f9f;
    }

    .time-estimation-item {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      justify-content: space-between;

      .time-estimation-item-content {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        h1 {
          font-size: 1.125rem;
          font-weight: 900;
        }

        p {
          color: #9f9f9f;
          font-size: 1rem;
        }
      }

      button {
        width: 9rem;
        height: 2rem;
        border-radius: 2px !important;
      }
    }
  }
}

.css-1oqwj6b-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  background-color: #1976d2 !important;
  color: #eee !important;
}

@media (max-width: 960px) {
  .tipcard img {
    display: none !important;
  }

  .tipcard-content h2 {
    font-size: 1.3rem !important;
    font-weight: 600;
    /* Reduce font size for smaller screens */
  }

  .tipcard-content h6 {
    font-size: 0.8rem !important;
    /* Adjust as necessary */
  }

  .btn-container {
    margin-right: 0 !important;
    /* Remove large margins on smaller screens */
    margin-top: 20px !important;
    /* Adjust spacing between button and text */
  }


  .tipcard {
    padding: 10px;
    /* Reduce padding for smaller screens */
  }
}

@media (max-width: 438px) {
  .tipcard {
    display: block !important;
  }

  .tipcard-content h2 {
    font-size: 1rem !important;
  }

  .btn-container a {
    font-size: 0.8rem;
  }

  .btn-container {
    margin-top: 0px !important;
  }
}


.tipcard {
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  color: #000000;
  width: 100%;
  display: flex;
  flex-direction: row !important;
  max-height: 10rem;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  border: 1px solid #d8d8d8;
  border-radius: 5px;

  img {
    width: 7.5rem;
    height: 7.5rem;
  }

  .close-popup {
    position: absolute;
    right: 16px;
    top: 0.0625rem;
    cursor: pointer;
    color: #9f9f9f;
    font-size: 1.75rem;
    font-weight: 500;
  }

  .tipcard-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    width: 100%;
    gap: 0.125rem;

    h1 {
      font-size: 1.5rem;
      font-weight: 900;
    }

    p {
      color: #000000;
      font-size: 1.5rem;
      font-weight: 900;
    }

    .tipcard-btns {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      button {
        width: 9rem;
        height: 2rem;
        border-radius: 2px !important;
      }
    }
  }
}

@media only screen and (max-width: 1160px) {
  .keyword-research-container .menu-btns-container {
    width: 830px !important;
    height: auto !important;
    // should always have 3 buttons in a row

  }
}


//  keywords reserach btns
@media (max-width:870px) and (min-width:769px) {
  .keyword-research-container .menu-btns-container {
    width: 450px !important;
  }

  .keyword-research-container .menu-btns-container .menu-btns {
    width: 210px !important;
  }

}

@media (max-width:601px) and (min-width:481px) {
  .keyword-research-container .menu-btns-container {
    width: 450px !important;
    flex: none;
  }

  .keyword-research-container .menu-btns-container .menu-btns {
    width: 210px !important;
  }
}


@media (max-width:380px) {
  .keyword-research-container .menu-btns-container.automation-project .menu-btns {
    width: 235px !important;
    margin-left: 13px !important;

  }

}


@media (max-width: 462px) {
  .abun-table-pagination-size-select {
    float: none !important;
    margin-bottom: 12px;
  }


  .abun-table-pagination-page-control {
    display: inline-block !important;
    float: none !important;
    margin-left: 14px;
  }
}


@media (max-width: 443px) {
  .abun-table-pagination-size-select {
    white-space: nowrap !important;
    font-size: 0.9em;
  }
}

// ================================================================================
// -----------------------------AbunModal Component Change ----------------------------
// ================================================================================

/* Modal header text styling */
.modal-header-text {
  margin-top: 10px;
  font-size: 1.5rem;
  /* Matches the heading size in the image */
  font-weight: 600;
  /* Semi-bold for emphasis */
  color: #1a1a1a;
  /* Darker text color */
  text-align: center;
  /* Center-align */
  margin-bottom: 10px;
  /* Add space between the header and the subtext */
  font-family: 'Arial', sans-serif;
  /* Replace with your preferred font-family */
}

/* Modal subtext styling */
.modal-subtext {
  font-size: 1rem;
  /* Matches the body text size */
  color: #666666;
  /* Softer gray for secondary text */
  text-align: center;
  /* Center-align */
  line-height: 1.5;
  /* Improves readability */
  font-family: 'Arial', sans-serif;
  /* Match font with header */
}

/* Backdrop blur effect */
.lg-tail-kw-modal-background {
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.5) !important;
  z-index: 40;
}

/* Modal styling */
.lg-tail-kw-abun-modal-card {
  border-radius: 12px !important;
  z-index: 50;
}

.language-article-context{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  flex-direction: row-reverse;
  top: 172px;
  margin-top: 10px;
  right: 59px;
  gap: 22px;
}


.language-selection,
.article-context-selection{
  display: flex;
  flex-direction: column ;
  align-items: center ;
  
  span{
    font-size: 18px;
    color: #333;
    font-weight: 600;
    margin-bottom: 2px;

  }
}

@media (min-width: 1970px) {
  .language-article-context{
    position: relative;
    top: 48px;
    margin-top: 10px;
    left: 498px;
  }
}

@media (max-width: 1080px){
  
   .language-selection{
     margin-bottom: 6px;
     margin-top: 5px;
   }

  .language-article-context{
   display: contents !important;
  }
  
}

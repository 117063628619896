$second-breakpoint: 1360px;

@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";
@import "../../assets/custom-global-styles/customGlobablStyles";

@import "bulma/sass/utilities/all";
@import "bulma/sass/base/all";
@import "bulma/sass/helpers/typography";
@import "bulma/sass/grid/columns";
@import "bulma/sass/components/card";
@import "bulma/sass/elements/button";
@import "bulma/sass/elements/tag";
@import "bulma/sass/elements/progress";

@import "../../assets/bulma-overrides/bulmaOverrides";

.offer-card {
  width: fit-content;
  background: white;
  border-radius: 200px;
  border: 2px solid #000000;
  box-shadow: 0px 4px 1px 0px #000000;
  padding: 10px 30px;
  margin: auto;
  margin-bottom: 50px;

  h2 {
    color: #7A63DF;
    font-family: "Bricolage Grotesque", Sans-serif;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.2px;
  }
}

.signup-plan-selection-container * {
  font-family: "Bricolage Grotesque", Sans-serif;
}

.subscription-manager-plan-cards-container {
  width: 95%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  grid-row-gap: 2em;
  margin-block: 1rem;

  @include until($second-breakpoint) {
    row-gap: 1rem;
    column-gap: 1rem;
  }


  .plan-card {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;

    background-color: $white;
    border: 3.25px solid #000;
    border-radius: 2rem;
    height: 52rem;
    padding: 1.25rem;
    zoom: .85;

    .plan-details--buy-button {
      background-color: #ffffff;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      border: 1px solid #000000 !important;
      width: 60%;
      height: auto;
      box-shadow: 1px 2px 0px 0px #000000;
      margin: 1em auto;
      color: $black !important;
      cursor: pointer;
      transition: all 0.3s ease;

      &:disabled {
        filter: grayscale(0.8);
        cursor: not-allowed;
        color: $black;
      }

      &:hover {
        color: $black;
        transform: translate(-0.25rem, -0.25rem);
        background: #fac44b !important;
        box-shadow: 0.25rem 0.25rem #000000;
      }
    }

    .pricing-gap {
      gap: 1em;
    }

    @include until($second-breakpoint) {
      padding: 1rem;
    }

    &:first-child {
      z-index: 1;

      @include until($second-breakpoint) {
        border: 4px solid #000;
        border-radius: 2rem;
      }
    }

    &:nth-child(2) {
      z-index: 2;
      margin-left: -1em;

      @include until($second-breakpoint) {
        border: 4px solid #000;
        border-radius: 2rem;
      }
    }

    &:nth-child(3) {
      z-index: 3;
      margin-left: -1em;

      @include until($second-breakpoint) {
        border: 4px solid #000;
        border-radius: 2rem;
      }
    }

    &:last-child {
      z-index: 5;
      margin-left: -1em;

      @include until($second-breakpoint) {
        border: 4px solid #000;
        border-radius: 2rem;
      }
    }

    hr {
      margin: 0.5rem auto;
      border: 0;
      border-top: 1px solid #ddddddaa;
      width: 80%;
    }

    .plan-name {
      font-size: 2rem;
      font-weight: 900;
      color: $black;
    }



    .cancel-pricing {
      font-weight: 500;
      font-size: 1.7rem;
      color: $grey-dark;
      text-decoration: line-through;
      white-space: nowrap;
    }

    .pricing {
      font-weight: 700;
      font-size: 3.1rem;
      color: $black;

      .pricing-suffix {
        margin-left: 0.5rem;
        font-size: 1.125rem;
        font-weight: 900;
        color: $black;
      }
    }

    .plan-details {
      display: flex;
      flex-direction: column;
      gap: 0.3em;

      .plan-details--item {
        font-size: 1rem;
        padding-bottom: 5px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        text-wrap: nowrap;

        //1650px;
        @include until(1650px) {
          font-size: 0.85rem;
        }

        span.icon {
          margin-right: 2px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          max-width: 1.375rem;
          max-height: 1.375rem;
        }
      }

    }

    &.popular {
      position: relative;
      border-radius: 2rem;
      background-color: #DAFFB5;
      transform: scale(1.075);

      @include until($second-breakpoint) {
        transform: scale(1);
      }

      .pricing {
        color: $black !important;
      }

      .plan-details--buy-button {
        background: #F2CE40;

        &:hover {
          transform: translate(0.2rem, 0.2rem);
          background-color: #ffffff !important;
          color: #000000 !important;
          border: 1px solid #000000;
          box-shadow: 0.25rem 0.25rem #00000000;
        }
      }
    }

  }

  @media (max-width:768px) {
    .plan-card {
      margin-left: 0.3em !important;
    }
  }
}

.popular {
  border-style: solid;
  border-color: $primary;
}

.stripe-portal-white-icon {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}


.usage-stats-section {
  width: 100%;

  .section-label {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 16px;
  }

  .usage-stats-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-column-gap: 1em;
    grid-row-gap: 2em;

    .usage-stats-item {
      display: block;
      /* background: white; */
      background-color: #fff;
      border-radius: .90rem;
      box-shadow: 0 .5em 1em -.125em #0000001a, 0 0 0 1px #00000005;
      color: #000;
      max-width: 100%;
      position: relative;
      padding: 1em;
      /* width: 0vw; */
      border: 1px solid black;

      .usage-stats-item--title {
        font-size: 1.2rem;
        font-weight: 700;
        margin-bottom: 1em;
      }

      .progress {
        margin-bottom: 1rem;
      }

      .usage-stats-item--info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 3px;

        p {
          font-size: 0.9rem;
        }

        .usage-stats-item--value{
          img{
            font-size: 2.2rem;
          }
        }
      }
    }
  }
}



@media (max-width:1444px) {
  .subscription-manager-plan-cards-container .plan-card .pricing {
    font-size: 2.21rem;
    white-space: nowrap;
  }

}


//  Free plan text when user switch to subscription plan
.switch-to-free-plan {
  width: auto !important
}



.billing-container {
  .billing-header {

    h3 {
      font-size: 2em;
      font-weight: bold;
      margin-bottom: 16px;
    }

    .btns-stripe-portal {
      width: fit-content;
      background: #FAC44B;
      border-radius: 200px;
      border: 1px solid #000000;
      box-shadow: 0px 4px 1px 0px #000000;
      padding: 10px 30px;
    }

    .btns-stripe-portal:hover {
      background-color: #ffffff !important;
      color: #000000 !important;
      box-shadow: 0rem 0rem #00000000;
      cursor: pointer;
    }
  }

  .table tbody {
    background-color: #f2f6fc;
  }
}


@media (max-width:493px) {
  .billing-header {
    flex-direction: column;
    margin-bottom: 1.5em;
  }
}

.header-text {
  color: #000;
  padding: 5px 0px;
}

// .cell-amount{
//   background-color: #f2f6fc ;
// }


// .table tbody{
//   background-color: #f2f6fc;
// }

.cancel-button {
  /* color: white; */
  color: black;
  background: white;
  border: 1px solid black;
  padding: 7px 30px;
  border-radius: 200px;
}

.cancel-button:hover {
  // background: rgb(38, 36, 36);
  color: rgb(255, 0, 0);
  cursor: pointer;
}

.subscription-page-abun-table td:hover{
  cursor: default !important;
}
@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

$input-placeholder-color: $grey-darker;

@import "bulma/sass/utilities/all";
@import "bulma/sass/base/all";
@import "bulma/sass/helpers/typography";
@import "bulma/sass/grid/columns";
@import "bulma/sass/elements/container";
@import "bulma/sass/components/tabs";
@import "bulma/sass/components/panel";
@import "bulma/sass/form/all";
@import "bulma-switch";

@import "../../assets/bulma-overrides/bulmaOverrides";
@import "../../assets/custom-global-styles/customGlobablStyles";

.settings-card {
  padding: 2rem;
}

.settings-section {
  display: flex;
  flex-direction: column;

  .hidden-template {
    display: none;
  }

  .templates-container {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(20.5rem, 0fr));
    gap: 2.5rem;

    .opacity-50 {
      opacity: 0.5;
    }

    .cursor-not-allowed {
      cursor: not-allowed;
    }

    .filter.grayscale {
      filter: grayscale(100%);
    }

    .template {
      // width: max-content;

      img {
        width: 21rem;
        border-radius: 1rem;
        object-fit: cover;
        border: 1px solid #ddd;
        height: 170px;

        &.selected-image {
          border: 0.25rem solid #6969eb;
        }
      }
    }

  }

  .tooltip-btn-container {
    width: max-content;
  }

  .settings-section-title {
    font: 500 1.2rem $bricolage-font;
  }

  .settings-radio-item-title {
    font: 400 1rem $secondary-font;
  }

  .settings-radio-item-description {
    font: 400 0.9rem $primary-font;
    color: $grey-darker;
    margin-top: 1em;
  }
}

.settings-integrations-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .settings-integrations-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    padding: 1.5em;
    border: solid 2px $grey;
    border-radius: 12px;

    transition: border-color 200ms linear;

    &:hover {
      border-color: $primary;
    }

    &.active {
      border-color: $success;
    }

    .active-icon {
      position: absolute;
      top: 8px;
      right: 8px;
    }

    p {
      margin: 10px 0 0 0;
      color: $black;
    }
  }
}

.integration-steps li {
  margin-bottom: 10px;
}


.integration-permissions li {
  margin-bottom: 5px;
}

.setting-current-integration-edit-button {
  align-self: center;
  margin-right: 1rem;
}

.setting-current-integration-remove-button {
  align-self: center;
  margin-right: 1rem;
}

.integration-modal-logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;

  img {
    height: 47px;
    width: auto;
    object-fit: contain;

    &.plus {
      height: 25px;
      margin-left: 2em;
      margin-right: 2em;
    }
  }

  .abun-modal-logo {
    height: 35px !important;
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  margin: auto;
}

.integration-steps {
  list-style: auto;
  margin-top: 10px;
  white-space: normal;


  .integration-permissions {
    list-style-type: disc;
    margin: -2.1875em 0 0 15.8em !important;
  }
}

@media (max-width:500px) {
  .integration-steps .integration-permissions {
    margin: 0 !important;
  }
}

.integration-current {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 4em;
  grid-row-gap: 2em;
}

.integrations-list {
  list-style: none;

  li.integration-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 2em;

    &:last-child {
      padding-bottom: 0;
    }

    .integration-item-logo {
      width: 48px;
      height: 48px;
    }

    .integration-item-name {
      position: relative;
      font-size: 1.2rem;
      font-weight: bold;
      margin-left: 1em;

      &.integration-item-installed {
        &::after {
          position: absolute;
          content: "• Active";
          font-size: 0.9rem;
          color: $success;
          min-width: 60px;
          left: 0;
          bottom: 0.1rem;
          //top:0.1rem;
        }
      }

      //  css of active text overlap
      &.integration-item-installed-google {
        &::after {
          position: absolute;
          content: "• Active";
          font-size: 0.9rem;
          color: $success;
          min-width: 60px;
          left: 0;
          bottom: 0.1rem;
        }
      }

      &.integration-item-not-installed {
        &::after {
          position: absolute;
          content: "Not Connected";
          font-size: 0.9rem;
          white-space: nowrap;
          color: $grey-darker;
          left: 0;
          bottom: 0.1rem;
          //top:0.3rem;
        }
      }


      //  css of not connected text overlap
      &.integration-item-not-installed-google {
        &::after {
          position: absolute;
          content: "Not Connected";
          font-size: 0.9rem;
          white-space: nowrap;
          color: $grey-darker;
          left: 0;
          bottom: 0.1rem;
        }
      }

      @media(max-width:819px) and (min-width: 768px) {
        &.integration-item-installed-google {
          &::after {
            bottom: -1rem;
          }
        }

        &.integration-item-not-installed-google {
          &::after {
            bottom: -1rem;
          }
        }

      }

      @media(max-width:543px) and (min-width:470px) {
        &.integration-item-installed-google {
          &::after {
            bottom: -1rem;
          }
        }

        &.integration-item-not-installed-google {
          &::after {
            bottom: -1rem;
          }
        }

      }

      @media(max-width:360px) {
        &.integration-item-installed-google {
          &::after {
            bottom: -1rem;
          }
        }

        &.integration-item-not-installed-google {
          &::after {
            bottom: -0.8rem;
          }
        }

      }


      &.integration-item-not-installed-trial-plan {
        &::after {
          bottom: 0.7rem;
        }
      }
    }

    .integration-button {
      width: 180px;
    }
  }
}

.website-details-container {
  display: flex;
  flex-direction: column;
  max-width: 600px;

  .website-details-save-button {
    max-width: 200px;
  }

  label.label {
    color: inherit;
    font-weight: 500;
  }
}

.profile-container {
  display: flex;
  flex-direction: column;
  max-width: 600px;

  .profile-save-button {
    max-width: 200px;
  }

  .disabled-color {
    color: #999898 !important;
  }
}

//.current-competitors-list {
//  display: grid;
//  grid-template-columns: repeat(4, 1fr);
//  grid-column-gap: 1em;
//  grid-row-gap: 1em;
//
//  .empty-competitor-space {
//    display: flex;
//    justify-content: center;
//    align-items: center;
//    width: 100%;
//    height: 100%;
//    border: dashed 2px $grey-darker;
//    min-height: 112px;
//    color: $grey-darker;
//  }
//}

.competitors-table-logo-container {
  width: 2rem;
  height: 2rem;
}

img.competitors-table-logo {
  width: 100%;
  min-width: 1.5rem;
  height: auto;
  object-fit: cover;
  border-radius: 50%;
}

.autopost-section-title {
  font: 500 1.2rem $secondary-font;
}


.btn-txt {
  padding-top: 1.5px;
}


#other-language-input {
  max-width: 200px;
}

.backlinks-settings {
  display: flex;
  flex-direction: column;
  max-width: 600px;

  input.input.max-backlinks-input {
    max-width: 40px;

    // disable arrows
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.unsaved-settings-warning button.leave-button {
  width: 150px;
}

@media (max-width:470px) {
  .content .integrations-list .integration-list-item {
    flex-direction: column;
    gap: 12px;
  }

}

// webflow integration modal
.integration-modal-content {
  flex-direction: column;
  align-items: flex-start !important;
}



// wordpress integration modal

.wp-subtitle {
  color: #808080;
  font-size: 1.1rem;
}

.card-content {

  .wp-alert-box {
    border: 1px solid gray;
    border-radius: 5px;
    padding: 1em;
    margin-bottom: 1em;

    span {
      font-size: 1.1rem;
    }

    .alert-msg {
      display: flex !important;
      flex-direction: column;
      align-items: flex-start !important;
      margin-left: 1.7rem;
      margin-top: -1.5rem;
    }

    b {
      font-size: 1.1rem;
    }
  }

  .wp-connect-meth {
    gap: .4em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;


    input[type="radio"] {
      appearance: none;
      width: 20px;
      height: 20px;
      border: 2px solid #333;
      border-radius: 50%;
      outline: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
  }
  
  input[type="radio"]:checked::before {
      content: "";
      width: 10px;
      height: 10px;
      background-color: #333;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }
  


    .radio-connect {
      display: flex;
      gap: 1rem;
      align-items: center;
    }
  }

  .accordion-trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 8px 16px;


    .arrow-icon {
      font-size: 16px;
      transition: transform 0.3s ease;
    }

    .arrow-icon.rotate {
      transform: rotate(180deg);
    }
  }


  .custom-button {
    width: 100%;
    background: #1c1c1c !important;
    border-radius: 8px;
    color: white;
    border: none;
  }
}

.eyeShow-password {
  position: absolute;
  top: 50%;
  right: 0.75rem;
  transform: translateY(-50%);
  cursor: pointer;
}
@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

$input-placeholder-color: $grey-darker;

@import "bulma/sass/utilities/all";
@import "bulma/sass/base/all";
@import "bulma/sass/form/all";
@import "bulma/sass/helpers/typography";

@import "../../assets/bulma-overrides/bulmaOverrides";
@import "../../assets/custom-global-styles/customGlobablStyles";

.profile-email-field {
  position: relative;

  img {
    position: absolute;
    top: 9px;
    right: 9px;
  }
}

.domain-image-upload {
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid #E5E5E5;
  border-radius: 0.5rem;
  margin-top: 1rem;

  .domain-image-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border: 1px solid #E5E5E5;
    border-radius: 0.5rem;
    margin-top: 1rem;
    height: auto;
    width: 25%;
  }

  .domain-image-upload-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border: 1px solid #E5E5E5;
    border-radius: 0.5rem;
    margin-top: 1rem;
  }
}
@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

@import "bulma/sass/utilities/all";
@import "bulma/sass/base/all";
@import "bulma/sass/helpers/typography";
@import "bulma/sass/grid/columns";
@import "bulma/sass/components/card";

@import "../../assets/bulma-overrides/bulmaOverrides";

.article-page-banner-card {
  background-color: $success !important;
}

.article-page-banner-title {
  font-size: 2.5rem;
  color: $white;
  font-family: $secondary-font;
  font-weight: bold;
}

.article-page-banner-subtitle {
  font-size: 1.6rem;
  margin: 0;
  line-height: 1.4;
  color: darken($success, 26%);
  font-family: $secondary-font;
}

.article-page-banner-button {
  background-color: $white !important;
  color: $black !important;
  font-family: $secondary-font !important;
}

.articles-table-card {
  width: 100%;

  @media (max-width:768px) {
    .btns-container{
      margin-top: 2rem !important;
    }   
  }
}

.articles-page--no-website-message-box {
  width: 100%;
  max-width: 100%;
  text-align: center;
}

.keywords-articles-manual-upload {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.seo-titles-edit-button {
  visibility: hidden;
  margin-left: 15px;
}

table tr:hover .seo-titles-edit-button {
  visibility: visible;
}

.seo-titles-edit-button:hover {
  cursor: pointer;
}

.keywords-articles-csv-upload {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .csv-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    border: dashed 2px $grey-darker;
    border-radius: 12px;
    cursor: pointer;

    img {
      width: 100px;
      height: 100px;
      filter: invert(100%) sepia(1%) saturate(1230%) hue-rotate(326deg) brightness(111%) contrast(77%);
    }

    p {
      font: normal 1.4rem $primary-font;
      color: $grey-darker;
      margin: 0;
      text-align: center;

      &.csv-filename {
        font-size: 1rem;
      }
    }

    input {
      display: none;
    }
  }

  .column-selection-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2em;

    select {
      width: 250px;
    }
  }
}

.publish-to-blog-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;

  img {
    width: 24px;
    height: auto;
  }
}

.view-on-blog-link {
  img {
    width: 24px;
    height: auto;
  }
}

button.hidden {
  display: none !important;
}

.publish-container {
  display: flex;
  align-items: center;

  .dropdown {
    position: relative;
    display: inline-block;

    .dropdown-icon {
      background-color: #fff;
      color: #000;
      border: none;
      cursor: pointer;
      // margin-left: 0.1rem;
      transition: transform 0.3s ease;
    }

    .rotate {
      transform: rotate(180deg);
    }

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      margin-top: 2px;
      margin-left: -8rem;
      overflow-y: auto;
      padding: 0 !important;
      border-radius: 8px;

      .dropdown-header {
        padding: 0.5rem .75rem;
        font-weight: 500;
        border-bottom: 1px solid #ddd;
      }

      .dropdown-item {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        padding: 0.5rem 1rem;

        &:hover {
          background-color: #f1f1f1;
        }
      }

      p {
        color: black;
        padding: 0 !important;
        margin: 0 .625rem !important;
        text-decoration: none;
        font-weight: 500;
        display: block;
        cursor: pointer;
      }

    }

    .show {
      display: block;
    }

  }

}
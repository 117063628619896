@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

$table-head-cell-color: $primary;
$table-color: $black;

@import "bulma/sass/utilities/all";
@import "bulma/sass/elements/table";
@import "bulma/sass/base/all";

// Adds extra styling to bulma table
// Use this in a <div> wrapping <table> element containing bulma's .table class
.abun-table-responsive {
  overflow: auto;
}

.abun-table-button-sm {
  font-size: 0.7rem !important;
}

.ml-1 {
  margin-left: 0.2rem !important;
}

.abun-table-container {

  &.abun-table-container--fullwidth {
    width: 100%;
  }

  .abun-table-filter-buttons {
    flex-direction: column;

    .btns-container {
      display: flex;
      flex-direction: row; // all this to increase responsiveness of the layout
      justify-content: space-between;
      margin-bottom: 1.5rem;
    }

    @media (max-width: 1380px) {
      .btns-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        width: auto;
        gap: 2.5px;
        margin-bottom: 2rem;
      }
    }

    @media (max-width: 768px) {
      .btns-container {
        margin-top: 6rem; // to proper spacing
      }
    }

    .selected-tab-info {
      color: #3F77F8;
      font-size: 1rem !important;
      text-align: center;
      flex-wrap: nowrap;
      line-height: 1.5rem;
      /* Adjust this based on your font-size */
      height: calc(2 * 1.5rem);
      /* Fixed height for exactly two lines */
      overflow: hidden;
      /* Hides any extra content */
      display: -webkit-box;
      /* Flexbox behavior with text */
      -webkit-line-clamp: 2;
      /* Limits the text to two lines */
      -webkit-box-orient: vertical;
      /* Needed for the line clamping */
      word-wrap: break-word;
      /* Wrap long words to prevent overflow */
      text-overflow: ellipsis;
      /* Adds "..." if content overflows */
      margin-top: 10px;
    }

    .filter-button {
      border-radius: .5rem;
      color: black;
      padding-left: 17px;
    }

    .filter-button:hover {
      //border-radius: .5rem;
      color: blue;
      //background-color: $primary;
    }

    .filter-button.active {
      background-color: $primary;
      color: $white;
      border: 1px solid $primary;
    }

    @media (max-width: 1380px) {

      // to align the top container
      .filter-button.active {
        margin-right: 12px;
      }
    }

    .filter-button:not(:first-child) {
      margin-left: -1em;
    }

    .filter-button:first-child {
      z-index: 5;
    }

    .filter-button:nth-child(2) {
      z-index: 4;
    }

    .filter-button:nth-child(3) {
      z-index: 3;
    }

    .filter-button:nth-child(4) {
      z-index: 2;
    }

    .filter-button:nth-child(5) {
      z-index: 1;
    }
  }

  .abun-table-search-input {
    max-width: 20em;
    font-size: 0.75em;
  }

  .abun-table-no-data {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: $grey-light;
    text-align: center;
  }

  .abun-table-pagination-controls-container {
    width: 100%;
    overflow: auto;

    .abun-table-pagination-stats {
      float: left;
      color: black;
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 0.9rem;
    }

    .abun-table-pagination-size-select {
      font-family: $inter-font;
      float: left;

      select {
        height: auto;
        padding-top: 0;
        padding-bottom: 0;
        font-size: 1rem;
        padding-right: 2em;
      }
    }

    .abun-table-pagination-page-control {
      display: flex;
      align-items: center;
      justify-content: space-around;
      float: right;
      width: 6.25rem;

      svg {
        cursor: pointer;
      }

      .page-input-container {
        display: inline-block;
        font-family: $inter-font;

        .page-input {
          display: inline-block;
          width: 1.75em;
          height: min-content;
          width: 1.75rem;
          height: 1.5rem;
          padding: 0.8px 5.8px 1.2px 5.8px;
          gap: 0px;
          border-radius: 4px;
          border: 1px solid #DBDBDB
        }
      }

      .page-icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 2em;
        height: 2em;
        border-radius: 100%;
        border: 2px solid $primary;
        background-color: $white;
        color: $primary;
        font-family: $secondary-font;
        font-size: 0.9rem;
        font-weight: 700;
        text-align: center;
        margin: 0 5px 0 5px;
        cursor: pointer;

        &:hover {
          background-color: $primary;
          color: $white;
        }

        &.page-active {
          background-color: $primary;
          color: $white;
        }
      }
    }
  }

  table {
    border-bottom: 2px solid hsl(0, 0%, 86%);
  }

  @media (max-width: 980px) {
    .responsive-table {
      table-layout: auto !important;
    }
  }
  

  th {
    font-family: $secondary-font;
    font-size: 1.1rem;
    padding: .45rem .675rem !important;

    .hide-column {
      display: none !important;
    }

    .sortable-column {
      width: max-content;
      cursor: pointer;
      gap: 10px;

      div:nth-of-type(2) {
        display: flex;
        width: max-content;
      }

      .sort-icon-container {

        span {
          padding: 0;
          margin: 0;
          font-size: 12px;

          &.upward-arrow {
            margin-bottom: -8px;
          }

          &.downward-arrow {
            margin-top: -8px;
          }
        }

        &.descending .upward-arrow {
          opacity: 0.5;
        }

        &.ascending .downward-arrow {
          opacity: 0.5;
        }

        &.not-sorted span {
          opacity: 0.5;
        }

      }

    }

    .unsortable-column {
      width: max-content;
    }
  }

  td {
    max-width: 400px;
    font-size: 0.9em;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .abun-table-content {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
    margin: 1.25rem 0;
    height: 80%;

    .abun-table-dropdown-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: .0625rem;

      .abun-table-dropdown-trigger {
        font-size: 0.75em;
        color: black;
        border: 1px solid #DBDBDB;
        background: #FFFFFF;
        box-shadow: 0px 1px 2px 1px #0000000D inset;
        width: 7rem;
        height: 1.875rem;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .75rem .5rem;
        pointer-events: none;

        &.available {
          border: 1px solid $primary;
          cursor: pointer;
          pointer-events: all;

          .active {
            color: $primary;
          }
        }

        svg {
          width: .75rem;
          height: .375rem;
        }

        span#bulk-action-dropdown-text {
          pointer-events: none;
        }
      }

      #bulk-action-dropdown-label[data-shrink="false"] {
        font-size: .75rem;
      }

      #bulk-action-dropdown {
        font-size: .875rem;
      }

      div.MuiInputBase-root {
        max-height: 2.1875rem;
      }

      .abun-table-dropdown-apply-btn {
        font-size: 0.75em;
        color: black;
        background-color: whitesmoke;
        border: none;
        width: 3.5rem;
        height: 1.875rem;
        pointer-events: none;
        margin: auto;

        &.active {
          pointer-events: all;
          background-color: $primary;
          color: $white;
          cursor: pointer;
        }
      }

      .abun-table-dropdown-menu-container {
        display: none !important;
        position: absolute;
        top: 10.5rem;
        left: 1.5rem;
        width: 7rem;
        background: #FFFFFF;
        display: flex;
        flex-direction: column;
        gap: 0.125rem;
        z-index: 1;

        &.show {
          display: flex !important;
        }

        .abun-table-dropdown-menu-item {
          padding: .25rem;
          cursor: pointer;
          text-align: center;
          font-size: 0.9em;
          border-radius: 4px;
          border: 1px solid #DBDBDB;

          &:hover {
            background-color: #F9F9F9;
            color: $primary !important;
          }
        }
      }
    }

    .balancer {
      width: 11rem;
      height: 1.875rem;

      &.hidden {
        display: none;
      }
    }

    &.no-bulk-action {
      .abun-table-dropdown-container {
        display: none !important;
      }

      .balancer.hidden {
        display: flex;
      }
    }
  }
}

.article-table-button-same-width {
  width: 120px;
}

input[type="checkbox"] {
  width: 15px;
  height: 15px;
}

.blurred-row {
  filter: blur(4px);
  pointer-events: none;
}

@media (max-width: 980px) {
  .table-filter-button-width {
    width: 100% !important;
    padding-left: 12px;
  }
}


@media (max-width: 500px) {
  .abun-table-content {
    align-items: center;
  }
}

.abun-table-search-input {
  margin-left: 12px;
}


@media (max-width: 450px) {
  .abun-table-pagination-size-select {
    float: none !important;
    margin-bottom: 12px;
  }


  .abun-table-pagination-page-control {
    display: inline-block !important;
    float: none !important;
    margin-left: 14px;
  }
}


@media (max-width: 373px) {
  .abun-table-pagination-size-select {
    white-space: nowrap !important;
    font-size: 0.9em;
  }
}